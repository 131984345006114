import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-style-guide';
import { UpsellService } from 'Roblox';
import usePhoneVerificationContext from '../hooks/usePhoneVerificationContext';
import { PhoneVerificationActionType } from '../store/action';
import { VERIFICATION_UPSELL_TRASLATION_KEY } from '../app.config';

import InlineChallenge from '../../../common/inlineChallenge';

const PhoneVerification: React.FC = () => {
  const {
    state: { renderInline, metricsService, eventService },
    dispatch
  } = usePhoneVerificationContext();

  const onClosed = useCallback(
    (isPhoneVerified: boolean) => {
      if (!isPhoneVerified) {
        dispatch({
          type: PhoneVerificationActionType.SET_CHALLENGE_INVALIDATED,
          errorCode: 0
        });
        eventService.sendChallengeInvalidatedEvent();
        metricsService.fireChallengeInvalidatedEvent();
        return;
      }

      dispatch({
        type: PhoneVerificationActionType.SET_CHALLENGE_COMPLETED,
        onChallengeCompletedData: {}
      });
      eventService.sendChallengeCompletedEvent();
      metricsService.fireChallengeCompletedEvent();
    },
    [dispatch, eventService, metricsService]
  );

  useEffect(() => {
    UpsellService.renderPhoneUpsell({
      onClose: onClosed,
      addPhoneHeadingKey: VERIFICATION_UPSELL_TRASLATION_KEY.Header.VerifyYourAccountHeader,
      addPhoneDescriptionKey:
        VERIFICATION_UPSELL_TRASLATION_KEY.Description.SuspiciousActivityPhoneVerification
    });
  }, [onClosed]);

  // Nothing to render as this challenge is simply wrapping the existing phone
  // verification upsell component.
  return renderInline ? (
    <InlineChallenge titleText='' />
  ) : (
    <Modal className='modal-modern' backdrop='static' />
  );
};

export default PhoneVerification;
