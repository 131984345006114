import { ErrorCode, OnChallengeCompletedData } from '../interface';

export enum PhoneVerificationActionType {
  SET_CHALLENGE_COMPLETED,
  SET_CHALLENGE_INVALIDATED
}

export type PhoneVerificationAction =
  | {
      type: PhoneVerificationActionType.SET_CHALLENGE_COMPLETED;
      onChallengeCompletedData: OnChallengeCompletedData;
    }
  | {
      type: PhoneVerificationActionType.SET_CHALLENGE_INVALIDATED;
      errorCode: ErrorCode;
    };
